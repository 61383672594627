import { useEffect, useState, Suspense, useRef, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from './apps/common/modules/auth/contexts/authContext';
import { setBearer, resetAuthorization } from './Services/AxiosService';
import Constants from './utils/Constants';
import { IAuth } from './utils/Interface/AuthInterface';
import { Toast_State } from './utils/ToastConstant';
import Auth from './apps/common/modules/auth/classes/Auth';
import Nav from './components/Nav';
import Loading from './components/common/Loading';
import SideNav from './components/SideNav';
import { useAppDispatch, useAppSelector } from './Redux/Store';
import { getRoutesList, handleEnvRedirect } from './utils/routes/Helpers';
import useIdleUserLogout from './utils/hooks/useIdleUserLogout';
import { feedlotManagerFeatures } from './apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { AxiosInterceptor } from './apps/common/modules/appLoader/components/AxiosInterceptor';
import { AppLoader } from './apps/common/modules/appLoader/components/AppLoader';
import { commonFeatures } from './apps/common/featureFlags/commonFeatures';
import { authActions } from './apps/common/modules/auth/authSlice';
import {
    ApplicationTabNames,
    DisplayTabNames,
} from './utils/Interface/CommonInterface';
import { COMMON_INITIAL_STATE } from './Redux/Constants/commonInitialState';
import { findDisplayFeedlotName } from './helpers';
import { fetchWindowSize } from './Redux/Reducer/common';
import { OwnerContextProvider } from './Context/OwnerContext/OwnerContext';

const { isCA10347_useAppLoaderHookOn } = commonFeatures;
const { isCA9836OnShouldRefreshOnIdle, isCA10695On_NamingTabs } =
    feedlotManagerFeatures;

const App = () => {
    const [auth] = useState<IAuth>(Auth());
    const [tokenRenewalComplete, setTokenRenewalComplete] = useState(false);
    const dispatch = useAppDispatch();
    const { toastMessage, isNavActive, selectedFeedlot, selectedLot } =
        useAppSelector(state => state.common);
    const [sideNavTab, setSideNavTab] = useState<string | number>('');
    useIdleUserLogout(auth.logout, auth.isAuthenticated);

    const timeoutId = useRef<NodeJS.Timeout | null>(null);
    const debounceTimeoutId = useRef<NodeJS.Timeout | null>(null);

    const resetTimer = useCallback(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            window.location.reload();
        }, Constants.time.idleTimeout);
    }, []);

    const debouncedResetTimer = useCallback(() => {
        if (debounceTimeoutId.current) {
            clearTimeout(debounceTimeoutId.current);
        }
        debounceTimeoutId.current = setTimeout(resetTimer, 500);
    }, [resetTimer]);

    useEffect(() => {
        if (isCA9836OnShouldRefreshOnIdle) {
            resetTimer();

            window.addEventListener('mousemove', debouncedResetTimer);
            window.addEventListener('keypress', debouncedResetTimer);

            return () => {
                window.removeEventListener('mousemove', debouncedResetTimer);
                window.removeEventListener('keypress', debouncedResetTimer);
                if (timeoutId.current) {
                    clearTimeout(timeoutId.current);
                }
                if (debounceTimeoutId.current) {
                    clearTimeout(debounceTimeoutId.current);
                }
            };
        }
    }, [debouncedResetTimer, resetTimer]);

    useEffect(() => {
        const resizeWin = () => {
            dispatch(fetchWindowSize(COMMON_INITIAL_STATE.windowSize));
        };
        window.addEventListener('resize', resizeWin);
        return () => window.removeEventListener('resize', resizeWin);
    }, [dispatch]);

    useEffect(() => {
        if (toastMessage.showMessage) {
            if (toastMessage.type === Constants.message.type.SUCCESS) {
                toast.success(toastMessage.message, { ...Toast_State });
            } else if (toastMessage.type === Constants.message.type.ERROR) {
                toast.error(toastMessage.message, { ...Toast_State });
            }
        }
    }, [toastMessage]);

    useEffect(() => {
        auth.renewToken(() => {
            setTokenRenewalComplete(true);
        });
    }, [auth]);

    const isAuthenticated = auth.isAuthenticated(false);

    useEffect(() => {
        //useEffect required to resolve this error: Warning: Cannot update a component (`App`) while rendering a different component (`App`). To locate the bad setState() call inside `App`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
        if (isAuthenticated) {
            dispatch(authActions.setAccessToken(auth.getAccessToken()));
        } else {
            dispatch(authActions.setAccessToken(''));
        }
    }, [auth, dispatch, isAuthenticated]);

    useEffect(() => {
        if (isCA10695On_NamingTabs) {
            if (sideNavTab.toString() === ApplicationTabNames.FeedlotManager) {
                const displayFeedlotName =
                    findDisplayFeedlotName(selectedFeedlot);
                if (selectedFeedlot.label && selectedLot) {
                    document.title = `${DisplayTabNames.FeedlotManager} | ${displayFeedlotName} | ${selectedLot}`;
                } else if (selectedFeedlot.label) {
                    document.title = `${DisplayTabNames.FeedlotManager} | ${displayFeedlotName}`;
                } else {
                    document.title = DisplayTabNames.FeedlotManager;
                }
            } else if (sideNavTab.toString() === ApplicationTabNames.FinApp) {
                if (selectedLot) {
                    document.title = `${DisplayTabNames.Financial} | ${selectedLot}`;
                } else {
                    document.title = DisplayTabNames.Financial;
                }
            } else if (sideNavTab.toString() === ApplicationTabNames.CattlePo) {
                document.title = DisplayTabNames.BuyPo;
            } else {
                document.title = DisplayTabNames.Default;
            }
        }
    }, [sideNavTab, selectedFeedlot, selectedLot]);

    if (!tokenRenewalComplete || handleEnvRedirect(auth)) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center vh-100 vw-100">
                <strong>Loading...</strong>
                <div
                    className="spinner-border ml-auto"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        );
    }

    if (isAuthenticated) {
        setBearer(auth.getAccessToken());
    } else {
        resetAuthorization();
    }

    return (
        <AuthContext.Provider value={auth}>
            {isCA10347_useAppLoaderHookOn ? (
                <AxiosInterceptor>
                    <div
                        id="default-focus-trap-element"
                        tabIndex={0}
                        style={{ width: 0, height: 0 }}
                    />
                    <ToastContainer />
                    <div className="container-fluid">
                        <div className="row">
                            <SideNav
                                sideNavTab={sideNavTab}
                                setSideNavTab={setSideNavTab}
                            />
                            <Suspense
                                fallback={
                                    <div className="m-auto">
                                        <Loading />
                                    </div>
                                }
                            >
                                <div
                                    className={`p-0 ${
                                        auth.isAuthenticated()
                                            ? `main-container ${
                                                  isNavActive
                                                      ? 'main-container-expand'
                                                      : ''
                                              }`
                                            : ''
                                    }`}
                                >
                                    <div id="content">
                                        <AppLoader />
                                        <OwnerContextProvider>
                                            <Nav sideNavTab={sideNavTab} />
                                            {getRoutesList()}
                                        </OwnerContextProvider>
                                    </div>
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </AxiosInterceptor>
            ) : (
                <>
                    <ToastContainer />
                    <div className="container-fluid">
                        <div className="row">
                            <SideNav
                                sideNavTab={sideNavTab}
                                setSideNavTab={setSideNavTab}
                            />

                            <Suspense
                                fallback={
                                    <div className="m-auto">
                                        <Loading />
                                    </div>
                                }
                            >
                                <div
                                    className={`p-0 ${
                                        auth.isAuthenticated()
                                            ? `main-container ${
                                                  isNavActive
                                                      ? 'main-container-expand'
                                                      : ''
                                              }`
                                            : ''
                                    }`}
                                >
                                    <div id="content">
                                        <OwnerContextProvider>
                                            <Nav sideNavTab={sideNavTab} />
                                            {getRoutesList()}
                                        </OwnerContextProvider>
                                    </div>
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </>
            )}
        </AuthContext.Provider>
    );
};

export default App;
