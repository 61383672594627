import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import Constants from '../../../../utils/Constants';

const { isCA9800On_ContractsMVP, isCA10418On_EnhanceScaleTicketsExports } =
    feedlotManagerFeatures;

export const tableConstants = {
    navigation: '',
    routeParameter: 'scaleTicket',
    headerAlign: 'text-center',
    headers: {
        scaleTicket: {
            label: 'Scale Ticket',
            apiResName: 'scaleTicketNumber',
        },
        TicketDate: {
            label: 'Ticket Date',
            apiResName: 'ticketDate',
            type: 'date',
        },
        Commodity: {
            label: 'Commodity',
            apiResName: 'commodity',
        },
        Vendor: {
            label: 'Vendor',
            apiResName: 'vendor',
        },
        Contract: {
            label: 'Contract',
            apiResName: 'contractName',
        },
        NetWeight: {
            label: 'Net Weight',
            apiResName: 'netWeight',
        },
        delete: {
            label: 'Action',
            type: 'deleteButton',
            apiResName: 'scaleTicket',
            canShowIconField: 'canDelete',
            actionName: 'scaleTicketDeleteHandler',
            canShowConfirmationModal: true,
            confirmationMessage: 'Are you sure you want to delete?',
        },
    },
};

export const ScaleTicketConstants = {
    title: 'Scale Ticket',
    inboundScaleTicket: 'Inbound Scale Ticket',
    outgoingScaleTicket: 'Outgoing Scale Ticket',
    createInboundScaleTicket: 'Create Inbound Scale Ticket',
    createOutgoingScaleTicket: 'Create Outgoing Scale Ticket',
    exportInboundScaleTickets: 'Export Inbound Scale Ticket',
    exportOutgoingScaleTickets: 'Export Outgoing Scale Ticket',
    exportScaleTickets: 'Export Scale Ticket',
    excelNameInbound: 'InboundScaleTicket',
    excelNameOutgoing: 'OutgoingScaleTicket',
    scaleTicketReport: {
        reportName: 'Scale Ticket Report',
        messages: {
            started: 'Downloading scale ticket report',
            error: 'Error downloading scale ticket report',
            success: 'Scale ticket report downloaded successfully',
        },
    },
    list: {
        title: 'Scale Ticket',
        limit: 20,
        offset: 0,
        tableForInboundScaleTicket: {
            ...tableConstants,
            navigation: Constants.Routes.UPDATE_INBOUND_SCALE_TICKETS,
        },
        tableForOutgoingScaleTicket: {
            ...tableConstants,
            navigation: Constants.Routes.UPDATE_OUTGOING_SCALE_TICKETS,
        },
        initialState: {
            filter: { scaleType: 1, limit: 20, offset: 0 },
        },
    },
    errorMessage: {
        mandatoryFields: 'Fill in Mandatory Fields',
        duplicateScaleTicketNumber:
            'There is already a created scale ticket with this Scale Ticket #. Please enter a unique scale ticket # to submit.',
    },
    mandatoryFields: [
        'tDate',
        'commodity',
        'contractId',
        'grossWeight',
        'tare',
        'truckingCompanyId',
        'scaleType',
        'vendor',
        'scaleTicketNumber',
    ],
    basicScaleTicketMandatoryFields: [
        'tDate',
        'commodity',
        'contractId',
        'vendor',
    ],
    mandatoryDropdown: [
        'commodity',
        'contractId',
        'truckingCompanyId',
        'vendor',
    ],
    dropDownName: {
        commodities: {
            commodityId: 'commodityId',
            name: 'name',
        },
        contract: {
            contractId: 'contractId',
            contractName: 'contractName',
        },
        truckingCompanies: {
            companyId: 'companyId',
            name: 'name',
        },
        vendor: {
            vendorId: 'vendorId',
            name: 'name',
        },
    },
    button: {
        okay: 'Okay',
    },
    tabs: {
        inboundScaleTicket: 1,
        outgoingScaleTicket: 2,
    },
    excelStaticData: [
        {
            scaleTicketNumber: 'Scale Ticket #',
            ticketDate: 'Ticket Date',
            commodity: 'Commodity',
            vendor: 'Vendor',
            contractName: 'Contract',
            ...(isCA10418On_EnhanceScaleTicketsExports
                ? {
                      grossWeight: 'Gross Weight',
                      tare: 'Tare Weight',
                  }
                : {}),
            netWeight: 'Net Weight',
            ...(isCA10418On_EnhanceScaleTicketsExports
                ? {
                      netWeightTons: 'Net (tons)',
                      dmPriceTon: 'DM Price (ton)',
                      billOfLading: 'Bill Of Lading',
                      comments: 'Comments',
                  }
                : {}),
        },
    ],
    reportsModal: {
        inboundTitle: 'Inbound Scale Ticket Records',
        outgoingTitle: 'Outgoing Scale Ticket Records',
        commonTitle: 'Scale Ticket Records',
        header: {
            oneMonth: '1 Month',
            threeMonths: '3 Months',
            sixMonths: '6 Months',
            customDays: 'Custom',
            startDate: 'Start Date',
            endDate: 'End Date',
            vendor: 'Vendor',
        },
        placeholders: {
            vendor: 'Select Vendor',
        },
        cancelButton: 'Cancel',
        submitButton: 'Export to Excel',
        vendorPdf: 'Vendor PDF',
    },
    initialOptionStatuses: {
        oneMonth: true,
        threeMonths: false,
        sixMonths: false,
        custom: false,
    },

    initialCustomDates: {
        startDate: '',
        endDate: '',
    },

    initialCustomDateErrors: {
        startDate: false,
        endDate: false,
    },
};

export const scaleTicketLabelConstants = {
    selectVendor: 'Select Vendor',
    createVendor: 'Create Vendor',
    selectContract: 'Select Contract',
    createContract: 'Create Contract',
    noContract: 'No Contract',
    assignContractLater: 'Assign Contract Later',
    selectCompany: 'Select Trucking Company',
    createTruckingCompany: 'Create Trucking Company',
    selectCommodity: 'Select Commodity',
    selectFreight: 'Select Freight',
    typeFob: 'FOB',
    typeDelivered: 'Delivered',
};

export const CreateScaleTicketConstants = {
    placeholders: {
        scaleTicket: 'Enter Ticket #',
        grossWeight: 'Enter Weight',
        tare: 'Enter Tare',
        freightRate: 'Enter Rate',
        driverName: 'Enter Name',
        comments: 'Enter Comments',
        billOfLading: 'Enter BOL',
        dmPercentage: 'Enter DM %',
    },
};

export const EditScaleTicketConstants = {
    cancel: 'Cancel',
    submit: 'Submit',
    labels: {
        date: 'Select Date',
        commodity: 'Commodity',
        vendor: 'Vendor',
        dmPercentage: 'Dry Matter %',
        contract: 'Contract',
        price: 'DM Price (per ton)',
        scaleTicket: 'Scale Ticket #',
        ticket: 'Ticket #',
        grossWeight: 'Gross Weight',
        tare: 'Tare',
        freightRate: 'Freight Rate',
        net: 'Net',
        netInTons: 'Net (in tons)',
        netInTon: 'Net(tons)',
        dmPercent: 'DM %',
        netTotal: 'Net Total:',
        netTonsTotal: 'Net Tons:',
        truckingCompany: 'Trucking Company',
        truckingCo: 'Trucking Co.',
        driverName: 'Driver Name',
        comments: 'Comments',
        billOfLading: 'Bill of Lading',
    },
    successMessage: {
        createInboundTicket: 'Inbound Scale Ticket Created Successfully.',
        updateInboundTicket: 'Inbound Scale Ticket Updated Successfully.',
        createOutgoingTicket: 'Outgoing Scale Ticket Created Successfully.',
        updateOutgoingTicket: 'Outgoing Scale Ticket Updated Successfully.',
    },
    placeholders: {
        price: '(autopopulated)',
        scaleTicket: 'Enter Scale Ticket #',
        grossWeight: 'Enter Gross Weight',
        tare: 'Enter Tare',
        netWeight: '(autopopulated)',
        netWeightInTons: '(autopopulated)',
        freightRate: 'Enter Freight Rate',
        driverName: 'Enter Driver Name',
        comments: 'Enter Comments',
        billOfLading: 'Enter Bill of Lading',
        dmPercentage: 'Enter Dry Matter %',
    },
    apiFields: {
        tDate: 'tDate',
        commodity: 'commodity',
        contractId: 'contractId',
        vendor: 'vendor',
        dmPercentage: 'dmPercentage',
        scaleTicketNumber: 'scaleTicketNumber',
        price: 'price',
        grossWeight: 'grossWeight',
        tare: 'tare',
        freightRate: 'freightRate',
        truckingCompanyId: 'truckingCompanyId',
        driverName: 'driverName',
        notes: 'notes',
        billOfLading: 'billOfLading',
    },
    lbsForTon: 2000,
    queryStrings: {
        isInboundScaleTicket: 'ScaleType eq 1',
        isOutboundScaleTicket: 'ScaleType eq 2',
    },
    scaleType: {
        inbound: 1,
        outbound: 2,
    },
    contractType: {
        noContract: -1,
        assignContractLater: -2,
    },
    confirmationMessage: {
        currentRate: `You have updated the contract for this scale ticket. Would you like to also update the scale ticket's freight rate of`,
        contractRate: `to match the contract's freight rate of`,
    },
    duplicateTicketInfo: 'Please enter a unique Ticket #.',
};

export const ScaleTicketModalConstants = {
    contractModal: {
        title: 'Create New Contract',
        labels: {
            contractName: 'Contract Name',
            vendor: 'Vendor',
            payee: 'Payee',
            creationDate: 'Creation Date',
            commodity: 'Commodity',
            price: 'DM Price (per ton)',
            totalAmount: 'Total Amount (in tons)',
            dmPercentage: 'Dry Matter %',
            freight: 'Freight',
            rate: 'Rate',
            comments: 'Comments',
            notPriced: 'Not Priced',
            contractTotal: 'Contract Total',
            scaleTicketsTotal: 'Scale Tickets Total',
            totalRemaining: 'Total Remaining',
        },
        mandatoryFields: isCA9800On_ContractsMVP
            ? [
                  'contractName',
                  'contractCreationDate',
                  'totalAmount',
                  'freightType',
                  'rate',
              ]
            : [
                  'contractName',
                  'contractCreationDate',
                  'price',
                  'totalAmount',
                  'freight',
                  'rate',
              ],
        allowedZeroFields: ['dmPercentage', 'totalAmount', 'rate'],
        apiFields: {
            contractName: 'contractName',
            vendorId: 'vendorId',
            payee: 'payee',
            creationDate: 'contractCreationDate',
            commodityId: 'commodityId',
            price: 'price',
            totalAmount: 'totalAmount',
            dmPercentage: 'dmPercentage',
            freight: 'freight',
            freightType: 'freightType',
            rate: 'rate',
            comment: 'comment',
            notPriced: 'notPriced',
        },
        placeholders: {
            contractName: 'Contract Name',
            price: 'Enter Price',
            payee: 'Enter Payee',
            totalAmount: 'Enter Total Amount',
            dmPercentage: 'Enter Dry Matter %',
            rate: 'Enter Rate',
            comments: 'Enter Comments',
        },
        button: {
            cancel: 'Cancel',
            submit: 'Submit',
        },
        successMessage: 'Contract Created Successfully.',
        updateSuccess: 'Contract Updated Successfully.',
        selectOnePriceTooltip: `Either DM Price (per ton) must be entered or "Not Priced" must be checked.`,
    },
    vendorModal: {
        title: 'Create New Vendor',
        labels: {
            vendorName: 'Vendor Name',
            contactName: 'Contact Name',
            phoneNumber: 'Phone Number',
            address: 'Address',
            city: 'City',
            state: 'State',
            zipcode: 'Zipcode',
        },
        mandatoryFields: [
            'name',
            'contactName',
            'contactNumber',
            'address',
            'city',
            'state',
            'zipCode',
        ],
        apiFields: {
            name: 'name',
            contactName: 'contactName',
            contactNumber: 'contactNumber',
            address: 'address',
            city: 'city',
            state: 'state',
            zipCode: 'zipCode',
        },
        placeholders: {
            vendorName: 'Enter Vendor Name',
            contactName: 'Enter Contact Name',
            phoneNumber: 'Enter Phone Number',
            address: 'Enter Address',
            city: 'Enter City',
            state: 'Enter State',
            zipcode: 'Enter Zipcode',
        },
        button: {
            cancel: 'Cancel',
            submit: 'Submit',
        },
        successMessage: 'Vendor Created Successfully.',
    },
    truckingCompanyModal: {
        title: 'Create New Trucking Company',
        labels: {
            truckingCompany: 'Trucking Company Name',
            manager1: 'Manager Name 1',
            manager2: 'Manager Name 2',
            phoneNumber1: 'Phone Number 1',
            phoneNumber2: 'Phone Number 2',
            emailAddress1: 'Email Address 1',
            emailAddress2: 'Email Address 2',
        },
        mandatoryFields: ['name', 'email', 'contactNumber', 'contactName'],
        apiFields: {
            companyId: 'companyId',
            name: 'name',
            email: 'email',
            altEmail: 'altEmail',
            contactNumber: 'contactNumber',
            altContactNumber: 'altContactNumber',
            contactName: 'contactName',
            altContactName: 'altContactName',
        },
        placeholders: {
            truckingCompany: 'Enter Trucking Company Name',
            manager1: 'Enter Manager Name 1',
            manager2: 'Enter Manager Name 2',
            phoneNumber1: 'Enter Phone Number 1',
            phoneNumber2: 'Enter Phone Number 2',
            emailAddress1: 'Enter Email Address 1',
            emailAddress2: 'Enter Email Address 2',
        },
        button: {
            cancel: 'Cancel',
            submit: 'Submit',
        },
        successMessage: 'Trucking Company Created Successfully.',
    },
};
