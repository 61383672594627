import { useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import {
    createTruckingCompanyService,
    setEditScaleTicketDetails,
    setTruckingCompanyModalDetails,
    setTruckingCompanyModalDetailsError,
} from '../actions/ScaleTicketActions';
import { SCALE_TICKET_INITIAL_STATE } from '../constants/scaleTicketsInitialState';
import { ScaleTicketModalConstants } from '../constants/ScaleTicketConstants';
import {
    IFilterFieldBasicDetails,
    IScaleTicketModal,
} from '../interfaces/ScaleTicketInterface';
import { validateAccordingType } from '../../../../utils/validation';

const CreateTruckingCompanyModal = ({ show, onHide }: IScaleTicketModal) => {
    const {
        truckingCompanyModalDetails,
        truckingCompanyModalDetailsError,
        editScaleTicketDetails,
    } = useAppSelector(state => state.scaleTicket);

    const dispatch = useAppDispatch();

    const validateMandatoryDetails = () => {
        const truckingCompanyModalDetailsErrorCopy = {
            ...truckingCompanyModalDetailsError,
        };
        const truckingCompanyModalDetailsFields = Object.keys(
            truckingCompanyModalDetailsErrorCopy,
        );
        let isValid = true;

        truckingCompanyModalDetailsFields.forEach(fieldName => {
            if (
                !truckingCompanyModalDetails[fieldName] &&
                ScaleTicketModalConstants.truckingCompanyModal.mandatoryFields.includes(
                    fieldName,
                )
            ) {
                truckingCompanyModalDetailsErrorCopy[fieldName] = true;
                isValid = false;
            }
        });

        if (!isValid) {
            dispatch(
                setTruckingCompanyModalDetailsError(
                    truckingCompanyModalDetailsErrorCopy,
                ),
            );
        }

        return isValid;
    };

    const resetAllStates = () => {
        dispatch(
            setTruckingCompanyModalDetails({
                ...SCALE_TICKET_INITIAL_STATE.truckingCompanyModalDetails,
            }),
        );
        dispatch(
            setTruckingCompanyModalDetailsError({
                ...SCALE_TICKET_INITIAL_STATE.truckingCompanyModalDetailsError,
            }),
        );
    };

    const onCancel = () => {
        onHide();
    };

    const updateEditScaleTicketDetails = (truckingCompanyId: number) => {
        const editScaleTicketDetailsCopy = {
            ...editScaleTicketDetails,
            truckingCompanyId,
        };

        dispatch(setEditScaleTicketDetails(editScaleTicketDetailsCopy));
    };

    const onSubmit = () => {
        const isValidMandatoryDetails = validateMandatoryDetails();

        if (isValidMandatoryDetails) {
            dispatch(
                createTruckingCompanyService(
                    truckingCompanyServicePayload(),
                    updateEditScaleTicketDetails,
                    onHide,
                ),
            );
        }
    };

    const truckingCompanyServicePayload = () => {
        return {
            name: truckingCompanyModalDetails.name,
            email: truckingCompanyModalDetails.email,
            altEmail: truckingCompanyModalDetails.altEmail,
            contactNumber: truckingCompanyModalDetails.contactNumber,
            altContactNumber: truckingCompanyModalDetails.altContactNumber,
            contactName: truckingCompanyModalDetails.contactName,
            altContactName: truckingCompanyModalDetails.altContactName,
        };
    };

    const truckingCompanyModalDetailsErrorHandler = (fieldName: string) => {
        const truckingCompanyModalDetailsErrorCopy = {
            ...truckingCompanyModalDetailsError,
            [fieldName]: false,
        };

        dispatch(
            setTruckingCompanyModalDetailsError(
                truckingCompanyModalDetailsErrorCopy,
            ),
        );
    };

    const truckingCompanyModalDetailsHandler = (
        fieldName: IFilterFieldBasicDetails,
        fieldValue: string | number | Date | null,
        type?: string,
    ) => {
        const isValid: boolean = validateAccordingType(type, fieldValue);

        if (isValid) {
            const truckingCompanyModalDetailsCopy = {
                ...truckingCompanyModalDetails,
                [fieldName]: fieldValue,
            };

            truckingCompanyModalDetailsErrorHandler(fieldName);
            dispatch(
                setTruckingCompanyModalDetails(truckingCompanyModalDetailsCopy),
            );
        }
    };

    useEffect(() => {
        resetAllStates();
    }, [show]);

    return (
        <Modal
            className="px-2 pt-5"
            data-size="md"
            show={show}
            centered
            animation
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">
                        {ScaleTicketModalConstants.truckingCompanyModal.title}
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mx-0 mb-lg-2">
                    <div className="col-xl-5 col-lg-5 start-top mb-2">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .labels.truckingCompany
                            }
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .apiFields.name
                            }
                            value={truckingCompanyModalDetails.name}
                            placeholder={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .placeholders.truckingCompany
                            }
                            className={`text-secondary form-item border border-${
                                truckingCompanyModalDetailsError.name
                                    ? 'danger'
                                    : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                truckingCompanyModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="row mx-0 mb-lg-2">
                    <div className="col-xl-5 col-lg-5 mb-2 start-top">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .labels.manager1
                            }
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .apiFields.contactName
                            }
                            value={truckingCompanyModalDetails.contactName}
                            placeholder={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .placeholders.manager1
                            }
                            className={`text-secondary form-item border border-${
                                truckingCompanyModalDetailsError.contactName
                                    ? 'danger'
                                    : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                truckingCompanyModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-xl-5 col-lg-5 mb-2 start-top pe-0">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .labels.manager2
                            }
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .apiFields.altContactName
                            }
                            value={truckingCompanyModalDetails.altContactName}
                            placeholder={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .placeholders.manager2
                            }
                            className={`text-secondary form-item border border-rounded`}
                            onChange={e => {
                                truckingCompanyModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                            size="sm"
                            type="text"
                        />
                    </div>
                </div>
                <div className="row mx-0 mb-lg-2">
                    <div className="col-xl-5 col-lg-5 mb-2 start-top">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .labels.phoneNumber1
                            }
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .apiFields.contactNumber
                            }
                            value={truckingCompanyModalDetails.contactNumber}
                            placeholder={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .placeholders.phoneNumber1
                            }
                            className={`text-secondary form-item border border-${
                                truckingCompanyModalDetailsError.contactNumber
                                    ? 'danger'
                                    : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                truckingCompanyModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-xl-5 col-lg-5 mb-2 start-top pe-0">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .labels.phoneNumber2
                            }
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .apiFields.altContactNumber
                            }
                            value={truckingCompanyModalDetails.altContactNumber}
                            placeholder={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .placeholders.phoneNumber2
                            }
                            className={`text-secondary form-item border border-rounded`}
                            onChange={e => {
                                truckingCompanyModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                            size="sm"
                            type="text"
                        />
                    </div>
                </div>
                <div className="row mx-0 mb-lg-2">
                    <div className="col-xl-5 col-lg-5 mb-2 start-top">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .labels.emailAddress1
                            }
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .apiFields.email
                            }
                            value={truckingCompanyModalDetails.email}
                            placeholder={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .placeholders.emailAddress1
                            }
                            className={`text-secondary form-item border border-${
                                truckingCompanyModalDetailsError.email
                                    ? 'danger'
                                    : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                truckingCompanyModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-xl-5 col-lg-5 mb-2 start-top pe-0">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .labels.emailAddress2
                            }
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .apiFields.altEmail
                            }
                            value={truckingCompanyModalDetails.altEmail}
                            placeholder={
                                ScaleTicketModalConstants.truckingCompanyModal
                                    .placeholders.emailAddress2
                            }
                            className={`text-secondary form-item border border-rounded`}
                            onChange={e => {
                                truckingCompanyModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                            size="sm"
                            type="text"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                        onCancel();
                    }}
                >
                    {
                        ScaleTicketModalConstants.truckingCompanyModal.button
                            .cancel
                    }
                </button>
                <button
                    type="button"
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    {
                        ScaleTicketModalConstants.truckingCompanyModal.button
                            .submit
                    }
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateTruckingCompanyModal;
