import { Dispatch } from 'react';
import {
    formatArrayForDropDown,
    formatDate_deprecated,
} from '../../../../helpers';
import { apiCall, apiCall_v2 } from '../../../../Services/AxiosService';
import Constants from '../../../../utils/Constants';
import {
    EditScaleTicketConstants,
    ScaleTicketConstants,
    ScaleTicketModalConstants,
    scaleTicketLabelConstants,
} from '../constants/ScaleTicketConstants';
import { getToastContent } from '../../../../utils/ToastContent';
import { SCALE_TICKET_INITIAL_STATE } from '../constants/scaleTicketsInitialState';
import { AppDispatch } from '../../../../Redux/Store';
import {
    IContractFormDetails,
    ICreateScaleTicketPayload,
    IGenericScaleTicketDispatch,
    IPutPostEditScaleTicketPayload,
    IScaleTicketsFilter,
    IScaleTicketsResponse,
    IScaleTicketsResponseHandlerFn,
    ITruckingCompanyModaLDetails,
    IUpdateEditScaleTicketDetailsAction,
    IVendorModalDetails,
    ScaleTicketContractStatus,
} from '../interfaces/ScaleTicketInterface';
import { ScaleTicketActionTypes } from '../../../../Redux/Actions/index';
import { ICsvData } from '../../../../utils/Interface/CommonInterface';
import dayjs from '../../../../utils/dayjs';
import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { toastMessage } from '../../../../Redux/Reducer/common';

const {
    isCA9800On_ContractsMVP,
    isCA10570On_VendorList,
    isCA8340On_CompanyList,
    isCA10418On_EnhanceScaleTicketsExports,
} = feedlotManagerFeatures;

export const setLoading = (loading = SCALE_TICKET_INITIAL_STATE.loading) => {
    return {
        type: ScaleTicketActionTypes.SET_SCALE_TICKET_LOADING,
        payload: loading,
    };
};

export const setScaleTicketCanCallApi = (
    canCallApi = SCALE_TICKET_INITIAL_STATE.canCallApi,
) => {
    return {
        type: ScaleTicketActionTypes.SET_SCALE_TICKET_CAN_CALL_API,
        payload: canCallApi,
    };
};

export const setScaleTicketFilter = (
    filter = { ...SCALE_TICKET_INITIAL_STATE.scaleTicketsFilter },
) => {
    return {
        type: ScaleTicketActionTypes.SET_SCALE_TICKET_FILTER,
        payload: filter,
    };
};

export const setScaleTickets = (
    scaleTickets = [...SCALE_TICKET_INITIAL_STATE.scaleTickets],
) => {
    return {
        type: ScaleTicketActionTypes.SET_SCALE_TICKETS,
        payload: scaleTickets,
    };
};

export const setSelectedScaleTicketTab = (
    selectedScaleTicketTab = SCALE_TICKET_INITIAL_STATE.selectedScaleTicketTab,
) => {
    return {
        type: ScaleTicketActionTypes.SET_SELECTED_SCALE_TICKET_TAB,
        payload: selectedScaleTicketTab,
    };
};

export const setScaleTicketSubmitPending = (
    submitPending = SCALE_TICKET_INITIAL_STATE.submitPending,
) => {
    return {
        type: ScaleTicketActionTypes.SET_SCALE_TICKET_SUBMIT_PENDING,
        payload: submitPending,
    };
};

export const fetchScaleTicketService =
    (
        payload: string,
        feedlotId,
        scaleTicketResponseHandler?: IScaleTicketsResponseHandlerFn,
        isExcelDownload = false,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));
        const scaleTicketsResponse = await apiCall(
            'get',
            `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.SCALE_TICKET}?${payload}`,
            {},
            true,
        );

        if (scaleTicketsResponse && scaleTicketsResponse.status) {
            if (
                scaleTicketsResponse.status >= Constants.responseCode.SUCCESS &&
                scaleTicketsResponse.status <
                    Constants.responseCode.MULTIPLE_CHOICES
            ) {
                const formattedScaleTicketsResponse: IScaleTicketsResponse[] =
                    scaleTicketsResponse.data.map(scaleTicketDetail => ({
                        scaleTicket: scaleTicketDetail.scaleTicketId,
                        scaleTicketNumber: scaleTicketDetail.scaleTicketNumber,
                        ticketDate: scaleTicketDetail.tDate,
                        contractStatus: isCA9800On_ContractsMVP
                            ? scaleTicketDetail.contractStatus
                            : undefined,
                        commodity: isCA9800On_ContractsMVP
                            ? (scaleTicketDetail.commodityName ??
                              scaleTicketDetail.contract?.commodity?.name)
                            : scaleTicketDetail.contract.commodity.name,
                        vendor: isCA9800On_ContractsMVP
                            ? (scaleTicketDetail.vendor?.name ??
                              scaleTicketDetail.contract?.vendor?.name)
                            : scaleTicketDetail.contract.vendor.name,
                        contractName: isCA9800On_ContractsMVP
                            ? (scaleTicketDetail.contract?.contractName ??
                              (scaleTicketDetail.contractStatus ===
                              ScaleTicketContractStatus.NoContract
                                  ? scaleTicketLabelConstants.noContract
                                  : scaleTicketLabelConstants.assignContractLater))
                            : scaleTicketDetail.contract.contractName,
                        netWeight: scaleTicketDetail.net,
                        billOfLading: scaleTicketDetail.billOfLading,
                        canDelete: scaleTicketDetail.canDelete,
                        ...(isCA10418On_EnhanceScaleTicketsExports
                            ? {
                                  grossWeight: scaleTicketDetail.grossWeight,
                                  tare: scaleTicketDetail.tare,
                                  price: scaleTicketDetail.price,
                                  notes: scaleTicketDetail.notes,
                              }
                            : {}),
                    }));

                if (isExcelDownload) {
                    const excelData = formatScaleTicketsForExcel(
                        formattedScaleTicketsResponse,
                    );
                    dispatch(
                        setScaleTicketExcelData([
                            ...ScaleTicketConstants.excelStaticData,
                            ...excelData,
                        ]),
                    );
                }
                if (!isExcelDownload && scaleTicketResponseHandler) {
                    scaleTicketResponseHandler(formattedScaleTicketsResponse);
                }
            }
        }
        dispatch(setLoading(false));
    };

export const formatScaleTicketsForExcel = (
    response: IScaleTicketsResponse[],
) => {
    const formattedScaleTickets: ICsvData[] = [];

    response.forEach(scaleTicketDetail => {
        formattedScaleTickets.push({
            scaleTicketNumber: +scaleTicketDetail.scaleTicketNumber,
            ticketDate: dayjs(scaleTicketDetail.ticketDate).format(
                'MM/DD/YYYY',
            ),
            commodity: scaleTicketDetail.commodity,
            vendor: scaleTicketDetail.vendor,
            contractName: scaleTicketDetail.contractName,
            netWeight: +scaleTicketDetail.netWeight,
            ...(isCA10418On_EnhanceScaleTicketsExports
                ? {
                      grossWeight: +scaleTicketDetail.grossWeight,
                      tare: +scaleTicketDetail.tare,
                      netWeightTons: +scaleTicketDetail.netWeight / 2000,
                      dmPriceTon: +scaleTicketDetail.price,
                      billOfLading: scaleTicketDetail.billOfLading,
                      comments: scaleTicketDetail.notes,
                  }
                : {}),
        });
    });

    return formattedScaleTickets;
};

export const setScaleTicketExcelData = (
    scaleTicketsExcelData = SCALE_TICKET_INITIAL_STATE.scaleTicketsExcelData,
) => {
    return {
        type: ScaleTicketActionTypes.SET_SCALE_TICKETS_EXCEL_DATA,
        payload: scaleTicketsExcelData,
    };
};

export const setCommodities = (
    commodities = [...SCALE_TICKET_INITIAL_STATE.commodities],
) => {
    return {
        type: ScaleTicketActionTypes.SET_COMMODITIES,
        payload: commodities,
    };
};

export const fetchCommodities = feedlotId => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    const commoditiesResponse = await apiCall(
        'get',
        `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.COMMODITY}?sort=Name`,
    );

    const commoditiesApiFields = ScaleTicketConstants.dropDownName.commodities;
    const formatLotFields = formatArrayForDropDown(
        commoditiesResponse,
        commoditiesApiFields.commodityId,
        commoditiesApiFields.name,
    );

    dispatch(
        setCommodities([
            { ...SCALE_TICKET_INITIAL_STATE.commodities[0] },
            ...(formatLotFields ?? []),
        ]),
    );
    dispatch(setLoading(false));
};

export const setVendor = (
    vendors = [...SCALE_TICKET_INITIAL_STATE.vendors],
) => {
    return {
        type: ScaleTicketActionTypes.SET_VENDOR,
        payload: vendors,
    };
};

export const fetchVendor =
    (vendorId?: number) => async (dispatch: AppDispatch) => {
        let filter = `filter= active eq true`;
        if (vendorId) {
            filter += ` or vendorId eq ${vendorId}`;
        }
        dispatch(setLoading(true));
        const vendorResponse = await apiCall(
            'get',
            isCA10570On_VendorList
                ? `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.VENDOR}/odata?sort=Name&${filter}`
                : `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.VENDOR}?sort=Name`,
        );

        const vendorApiFields = ScaleTicketConstants.dropDownName.vendor;
        const formatLotFields = formatArrayForDropDown(
            vendorResponse,
            vendorApiFields.vendorId,
            vendorApiFields.name,
        );

        dispatch(
            setVendor([
                ...SCALE_TICKET_INITIAL_STATE.vendors,
                ...(formatLotFields ?? []),
            ]),
        );
        dispatch(setLoading(false));
    };

export const setContract = (
    contracts = [...SCALE_TICKET_INITIAL_STATE.contracts],
) => {
    return {
        type: ScaleTicketActionTypes.SET_CONTRACT,
        payload: contracts,
    };
};

export const setCompleteContract = (
    completeContracts = [...SCALE_TICKET_INITIAL_STATE.completeContracts],
) => {
    return {
        type: ScaleTicketActionTypes.SET_COMPLETE_CONTRACTS,
        payload: completeContracts,
    };
};

export const fetchContract =
    (
        feedlotId: number,
        scaleType: string,
        commodityId?: number,
        vendorId?: number,
    ) =>
    async (dispatch: AppDispatch) => {
        let filter = '';
        if (commodityId && vendorId) {
            if (isCA9800On_ContractsMVP) {
                filter = `&commodityId=${commodityId}&vendorId=${vendorId}&abbreviated=false`;
            } else {
                filter = `&commodityId=${commodityId}&vendorId=${vendorId}`;
            }
        }
        dispatch(setLoading(true));
        const contractResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.CONTRACT}?sort=ContractName&include=Contracts&filter=ContractType eq ${scaleType} || ContractType eq Both${filter}`,
        });
        const contractApiFields = ScaleTicketConstants.dropDownName.contract;
        const formatLotFields = formatArrayForDropDown(
            contractResponse,
            contractApiFields.contractId,
            contractApiFields.contractName,
        );

        dispatch(setCompleteContract([...(formatLotFields ?? [])]));
        dispatch(
            setContract([
                ...SCALE_TICKET_INITIAL_STATE.contracts,
                ...(formatLotFields ?? []),
            ]),
        );
        dispatch(setLoading(false));
    };

export const setTruckingCompanies = (
    truckingCompanies = [...SCALE_TICKET_INITIAL_STATE.truckingCompanies],
) => {
    return {
        type: ScaleTicketActionTypes.SET_TRUCKING_COMPANIES,
        payload: truckingCompanies,
    };
};

export const fetchTruckingCompanies =
    (companyId?: number) => async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));
        let filter = `filter= isActive eq true`;
        if (companyId) {
            filter += ` or companyId eq ${companyId}`;
        }
        const truckingCompaniesResponse = await apiCall(
            'get',
            isCA8340On_CompanyList
                ? `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_TRUCKING_COMPANY}/odata?${filter}&orderBy=Name`
                : `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_TRUCKING_COMPANY}`,
        );

        const truckingCompaniesApiFields =
            ScaleTicketConstants.dropDownName.truckingCompanies;

        const formatLotFields = formatArrayForDropDown(
            truckingCompaniesResponse,
            truckingCompaniesApiFields.companyId,
            truckingCompaniesApiFields.name,
        );

        dispatch(
            setTruckingCompanies([
                ...SCALE_TICKET_INITIAL_STATE.truckingCompanies,
                ...(formatLotFields ?? []),
            ]),
        );
        dispatch(setLoading(false));
    };

export const setEditScaleTicketDetails = (
    editScaleTicketDetails = {
        ...SCALE_TICKET_INITIAL_STATE.editScaleTicketDetails,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_EDIT_TICKET_DETAIL,
        payload: editScaleTicketDetails,
    };
};

export const setCreateScaleTicketDetails = (
    createScaleTicketDetails = {
        ...SCALE_TICKET_INITIAL_STATE.createScaleTicketDetails,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_CREATE_TICKET_DETAIL,
        payload: createScaleTicketDetails,
    };
};

export const updateEditScaleTicketDetails = (
    editScaleTicketDetails: IUpdateEditScaleTicketDetailsAction = {
        ...SCALE_TICKET_INITIAL_STATE.editScaleTicketDetails,
    },
) => {
    return {
        type: ScaleTicketActionTypes.UPDATE_EDIT_TICKET_DETAIL,
        payload: editScaleTicketDetails,
    };
};

export const setEditScaleTicketDetailsError = (
    editScaleTicketDetailsError = {
        ...SCALE_TICKET_INITIAL_STATE.editScaleTicketDetailsError,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_EDIT_TICKET_DETAIL_ERROR,
        payload: editScaleTicketDetailsError,
    };
};

export const setCreateScaleTicketDetailsError = (
    createScaleTicketDetailsError = {
        ...SCALE_TICKET_INITIAL_STATE.createScaleTicketDetailsError,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_CREATE_TICKET_DETAIL_ERROR,
        payload: createScaleTicketDetailsError,
    };
};

export const setVendorModalDetails = (
    vendorModalDetails = {
        ...SCALE_TICKET_INITIAL_STATE.vendorModalDetails,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_VENDOR_MODAL_DETAILS,
        payload: vendorModalDetails,
    };
};

export const setVendorModalDetailsError = (
    vendorModalDetailsError = {
        ...SCALE_TICKET_INITIAL_STATE.vendorModalDetailsError,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_VENDOR_MODAL_DETAILS_ERROR,
        payload: vendorModalDetailsError,
    };
};

export const createVendorService =
    (
        payload: IVendorModalDetails,
        updateEditScaleTicketDetails: (vendorId: number) => void,
        onHide: () => void,
    ) =>
    async (
        dispatch: Dispatch<
            | IGenericScaleTicketDispatch
            | ((dispatch: AppDispatch) => Promise<void>)
        >,
    ) => {
        dispatch(setLoading(true));
        const response = await apiCall(
            'post',
            `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.VENDOR}`,
            payload,
            true,
        );

        if (response && response.status === Constants.responseCode.SUCCESS) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        ScaleTicketModalConstants.vendorModal.successMessage,
                    ),
                ),
            );
            dispatch(fetchVendor());

            updateEditScaleTicketDetails(response.data);

            onHide();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }
        dispatch(setLoading(false));
    };

export const setContractModalDetails = (
    contractModalDetails = {
        ...SCALE_TICKET_INITIAL_STATE.contractModalDetails,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_CONTRACT_MODAL_DETAILS,
        payload: contractModalDetails,
    };
};

export const setContractModalDetailsError = (
    contractModalDetailsError = {
        ...SCALE_TICKET_INITIAL_STATE.contractModalDetailsError,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_CONTRACT_MODAL_DETAILS_ERROR,
        payload: contractModalDetailsError,
    };
};

export const createContractService =
    (
        payload: IContractFormDetails,
        feedlotId: number,
        updateEditScaleTicketDetails: (
            contractId: number,
            price: string | number,
            rate?: string | number, //can be made non-optional when CA9800 is cleaned up
        ) => void,
        onHide: () => void,
    ) =>
    async (
        dispatch: Dispatch<
            | IGenericScaleTicketDispatch
            | ((dispatch: AppDispatch) => Promise<void>)
        >,
    ) => {
        dispatch(setLoading(true));
        const response = await apiCall(
            'post',
            `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.CONTRACT}`,
            payload,
            true,
        );

        if (response && response.status === Constants.responseCode.SUCCESS) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        ScaleTicketModalConstants.contractModal.successMessage,
                    ),
                ),
            );
            dispatch(
                fetchContract(
                    feedlotId,
                    payload.contractType,
                    payload.commodityId,
                    payload.vendorId,
                ),
            );

            updateEditScaleTicketDetails(
                response.data,
                Number(payload.price),
                isCA9800On_ContractsMVP ? Number(payload.rate) : undefined,
            );

            onHide();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }
        dispatch(setLoading(false));
    };

export const updateContract =
    (feedlotId: number, payload: IContractFormDetails, contractId: number) =>
    async (
        dispatch: Dispatch<
            | IGenericScaleTicketDispatch
            | ((dispatch: AppDispatch) => Promise<void>)
        >,
    ) => {
        dispatch(setLoading(true));
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.CONTRACT}/${contractId}`,
            payload: payload,
            isResRequired: true,
        });

        dispatch(setLoading(false));
        if (response && response.status === Constants.responseCode.NO_CONTENT) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        ScaleTicketModalConstants.contractModal.updateSuccess,
                    ),
                ),
            );
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }
    };
export const setTruckingCompanyModalDetails = (
    truckingCompanyModalDetails = {
        ...SCALE_TICKET_INITIAL_STATE.truckingCompanyModalDetails,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_TRUCKING_COMPANY_MODAL_DETAILS,
        payload: truckingCompanyModalDetails,
    };
};

export const setTruckingCompanyModalDetailsError = (
    truckingCompanyModalDetailsError = {
        ...SCALE_TICKET_INITIAL_STATE.truckingCompanyModalDetailsError,
    },
) => {
    return {
        type: ScaleTicketActionTypes.SET_TRUCKING_COMPANY_MODAL_DETAILS_ERROR,
        payload: truckingCompanyModalDetailsError,
    };
};

export const createTruckingCompanyService =
    (
        payload: ITruckingCompanyModaLDetails,
        updateEditScaleTicketDetails: (truckingCompanyId: number) => void,
        onHide,
    ) =>
    async (
        dispatch: Dispatch<
            | IGenericScaleTicketDispatch
            | ((dispatch: AppDispatch) => Promise<void>)
        >,
    ) => {
        dispatch(setLoading(true));
        const response = await apiCall(
            'post',
            `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_TRUCKING_COMPANY}`,
            payload,
            true,
        );

        if (response && response.status === Constants.responseCode.SUCCESS) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        ScaleTicketModalConstants.truckingCompanyModal
                            .successMessage,
                    ),
                ),
            );
            dispatch(fetchTruckingCompanies());
            if (response?.data?.data?.id) {
                updateEditScaleTicketDetails(response.data.data.id);
            }
            onHide();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }
        dispatch(setLoading(false));
    };

export const createMultiLineScaleTicketService =
    (
        payload: ICreateScaleTicketPayload,
        feedlotId: number,
        navigateToPreviousPage: () => void,
        setShowError,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(setScaleTicketSubmitPending(true));
        dispatch(setLoading(true));
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MULTILINE_SCALE_TICKET}`,
            payload: payload,
            isResRequired: true,
        });

        if (response && response.status === Constants.responseCode.SUCCESS) {
            if (payload.scaleType === 1) {
                dispatch(
                    toastMessage(
                        getToastContent(
                            response.status,
                            '',
                            EditScaleTicketConstants.successMessage
                                .createInboundTicket,
                        ),
                    ),
                );
            } else if (payload.scaleType === 2) {
                dispatch(
                    toastMessage(
                        getToastContent(
                            response.status,
                            '',
                            EditScaleTicketConstants.successMessage
                                .createOutgoingTicket,
                        ),
                    ),
                );
            }

            navigateToPreviousPage();
        } else if (response.status === `${Constants.responseCode.DUPLICATE}`) {
            setShowError({
                error: true,
                errorMessage:
                    ScaleTicketConstants.errorMessage
                        .duplicateScaleTicketNumber,
                buttonText: ScaleTicketConstants.button.okay,
            });
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }

        dispatch(setScaleTicketSubmitPending(false));
        dispatch(setLoading(false));
    };

export const createScaleTicketService =
    (
        payload: IPutPostEditScaleTicketPayload,
        feedlotId: number,
        navigateToPreviousPage: () => void,
        setShowError,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(setScaleTicketSubmitPending(true));
        dispatch(setLoading(true));
        const response = await apiCall(
            'post',
            `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.SCALE_TICKET}`,
            payload,
            true,
        );

        if (response && response.status === Constants.responseCode.SUCCESS) {
            if (payload.scaleType === 1) {
                dispatch(
                    toastMessage(
                        getToastContent(
                            response.status,
                            '',
                            EditScaleTicketConstants.successMessage
                                .createInboundTicket,
                        ),
                    ),
                );
            } else if (payload.scaleType === 2) {
                dispatch(
                    toastMessage(
                        getToastContent(
                            response.status,
                            '',
                            EditScaleTicketConstants.successMessage
                                .createOutgoingTicket,
                        ),
                    ),
                );
            }

            navigateToPreviousPage();
        } else if (response.status === `${Constants.responseCode.DUPLICATE}`) {
            setShowError({
                error: true,
                errorMessage:
                    ScaleTicketConstants.errorMessage
                        .duplicateScaleTicketNumber,
                buttonText: ScaleTicketConstants.button.okay,
            });
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }

        dispatch(setScaleTicketSubmitPending(false));
        dispatch(setLoading(false));
    };

export const fetchScaleTicketDetailsService =
    (feedlotId: number, scaleTicketId: number) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.SCALE_TICKET}/${scaleTicketId}`,
        });

        if (!response) return;
        const setScaleType = () => {
            if (response.scaleType.toLowerCase() === 'inbound') {
                return 1;
            } else if (response.scaleType.toLowerCase() === 'outgoing') {
                return 2;
            } else {
                return 1;
            }
        };

        const getContractStatus = () => {
            return (
                response.contract?.contractId ??
                (response.contractStatus ===
                ScaleTicketContractStatus.NoContract
                    ? EditScaleTicketConstants.contractType.noContract
                    : response.contractStatus ===
                        ScaleTicketContractStatus.Unassigned
                      ? EditScaleTicketConstants.contractType
                            .assignContractLater
                      : 0)
            );
        };

        if (response) {
            dispatch(
                setEditScaleTicketDetails({
                    tDate: formatDate_deprecated(response.tDate, 'YYYY-MM-DD'),
                    commodity: isCA9800On_ContractsMVP
                        ? (response.contract?.commodityId ??
                          response.commodityId)
                        : response.contract.commodityId,
                    contractId: isCA9800On_ContractsMVP
                        ? getContractStatus()
                        : response.contract.contractId,
                    price: response.price,
                    grossWeight: response.grossWeight,
                    tare: response.tare,
                    truckingCompanyId: response.truckingCompanyId,
                    freightRate: isCA9800On_ContractsMVP
                        ? (response.freightRate ?? response.contract?.rate)
                        : undefined,
                    dmPercentage: isCA9800On_ContractsMVP
                        ? response.dmPercentage
                        : undefined,
                    driverName: response.driverName,
                    notes: response.notes,
                    scaleType: setScaleType(),
                    vendor: isCA9800On_ContractsMVP
                        ? (response.contract?.vendor?.vendorId ??
                          response.vendorId)
                        : response.contract.vendor.vendorId,
                    scaleTicketNumber: response.scaleTicketNumber,
                    netWeight: 0,
                    netWeightInTons: 0,
                    billOfLading: response.billOfLading || '',
                }),
            );
        } else {
            dispatch(
                setEditScaleTicketDetails({
                    ...SCALE_TICKET_INITIAL_STATE.editScaleTicketDetails,
                }),
            );
        }
        return response;
    };

export const isTicketNumberExists = async (
    feedlotId: number,
    ticketId: number | string,
) => {
    const encodedTicketId = encodeURIComponent(ticketId.toString());
    const response = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.SCALE_TICKET}/odata?filter=scaleTicketNumber eq '${encodedTicketId}'`,
        isResRequired: true,
    });
    return response.data;
};

export const updateScaleTicketService =
    (
        payload: IPutPostEditScaleTicketPayload,
        scaleTicketId: number,
        feedlotId: number,
        navigateToPreviousPage: () => void,
        setShowError,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(setScaleTicketSubmitPending(true));
        dispatch(setLoading(true));
        const response = await apiCall(
            'put',
            `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.SCALE_TICKET}/${scaleTicketId}`,
            payload,
            true,
        );

        if (
            response &&
            (response.status === Constants.responseCode.NO_CONTENT ||
                response.status === Constants.responseCode.SUCCESS)
        ) {
            if (payload.scaleType === 1) {
                dispatch(
                    toastMessage(
                        getToastContent(
                            response.status,
                            '',
                            EditScaleTicketConstants.successMessage
                                .updateInboundTicket,
                        ),
                    ),
                );
            } else if (payload.scaleType === 2) {
                dispatch(
                    toastMessage(
                        getToastContent(
                            response.status,
                            '',
                            EditScaleTicketConstants.successMessage
                                .updateOutgoingTicket,
                        ),
                    ),
                );
            }

            navigateToPreviousPage();
        } else if (response.status === `${Constants.responseCode.DUPLICATE}`) {
            setShowError({
                error: true,
                errorMessage:
                    ScaleTicketConstants.errorMessage
                        .duplicateScaleTicketNumber,
                buttonText: ScaleTicketConstants.button.okay,
            });
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }

        dispatch(setScaleTicketSubmitPending(false));
        dispatch(setLoading(false));
    };

export const deleteScaleTicket =
    (
        scaleTicketId: number,
        feedlotId: number,
        filter: IScaleTicketsFilter,
        getScaleTicketList: () => void,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));
        const deleteApiUrl = `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.SCALE_TICKET}/${scaleTicketId}`;

        const response = await apiCall('delete', deleteApiUrl, {}, true);

        if (
            response &&
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(response.status, 'delete')));
            if (filter.offset) {
                dispatch(
                    setScaleTicketFilter({
                        ...SCALE_TICKET_INITIAL_STATE.scaleTicketsFilter,
                    }),
                );
            } else {
                getScaleTicketList();
            }
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }

        dispatch(setLoading(false));
    };
