import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import {
    ICreateScaleTicketAdvanceDetails,
    ICreateScaleTicketError,
    ICreateScaleTicketSegments,
    IFilterFieldBasicDetails,
} from '../interfaces/ScaleTicketInterface';
import {
    CreateScaleTicketConstants,
    EditScaleTicketConstants,
    scaleTicketLabelConstants,
} from '../constants/ScaleTicketConstants';
import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { SCALE_TICKET_INITIAL_STATE } from '../constants/scaleTicketsInitialState';
import AddRowButton from '../../../../components/common/AddRowButton';
import DeleteIcon from '../../../../Assets/DeleteIcon';
import { validateAccordingType } from '../../../../utils/validation';
import { useEffect, useState } from 'react';
import {
    fetchTruckingCompanies,
    isTicketNumberExists,
    setCreateScaleTicketDetails,
    setCreateScaleTicketDetailsError,
} from '../actions/ScaleTicketActions';
import { useFeedlotId } from '../../../../commonHooks/useFeedlotId';
import { useModalStatus } from '../../../../commonHooks/useModalStatus';
import TruckingCompanyModal from '../../../../components/pages/TruckingCompanyModal';
import CreateTruckingCompanyModal from './CreateTruckingCompanyModal';
import { TRUCKING_COMPANY_INITIAL_STATE } from '../../../../Redux/Constants/truckingCompanyInitialState';

const { isCA9800On_ContractsMVP, isCA8340On_CompanyList } =
    feedlotManagerFeatures;

const CreateScaleTicketAdvanceDetails = ({
    scaleTicketDetailsErrorHandler,
}: ICreateScaleTicketAdvanceDetails) => {
    const {
        createScaleTicketDetails,
        createScaleTicketDetailsError,
        truckingCompanies,
    } = useAppSelector(state => state.scaleTicket);
    const truckingCompanyModal = useModalStatus();
    const [totalNetWeight, setTotalNetWeight] = useState(0);
    const [totalNetWeightInTons, setTotalNetWeightInTons] = useState(0);
    const [truckingCompanyIndex, setTruckingCompanyIndex] = useState(0);
    const [validationInProgress, setValidationInProgress] = useState(false);
    const [addRowButtonClicked, setAddRowButtonClicked] = useState(false);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();

    const updateScaleTicketDetails = (
        updatedSegments: ICreateScaleTicketSegments[],
    ) => {
        dispatch(
            setCreateScaleTicketDetails({
                ...createScaleTicketDetails,
                scaleTicketSegments: updatedSegments,
            }),
        );
    };

    const updateScaleTicketErrors = (
        updatedErrors: ICreateScaleTicketError,
    ) => {
        dispatch(setCreateScaleTicketDetailsError(updatedErrors));
    };

    const addScaleTicket = () => {
        if (!validationInProgress) {
            setAddRowButtonClicked(false);
            const updatedSegments = [
                ...createScaleTicketDetails.scaleTicketSegments,
                ...SCALE_TICKET_INITIAL_STATE.createScaleTicketDetails
                    .scaleTicketSegments,
            ];
            updateScaleTicketDetails(updatedSegments);
            updateScaleTicketErrors({
                ...createScaleTicketDetailsError,
                scaleTicketSegmentError: [
                    ...createScaleTicketDetailsError.scaleTicketSegmentError,
                    SCALE_TICKET_INITIAL_STATE.createScaleTicketDetailsError
                        .scaleTicketSegmentError[0],
                ],
            });
        }
    };

    const removeScaleTicket = (index: number) => {
        const updatedSegments = [
            ...createScaleTicketDetails.scaleTicketSegments.slice(0, index),
            ...createScaleTicketDetails.scaleTicketSegments.slice(index + 1),
        ];
        updateScaleTicketDetails(updatedSegments);
        updateScaleTicketErrors({
            ...createScaleTicketDetailsError,
            scaleTicketSegmentError: [
                ...createScaleTicketDetailsError.scaleTicketSegmentError.slice(
                    0,
                    index,
                ),
                ...createScaleTicketDetailsError.scaleTicketSegmentError.slice(
                    index + 1,
                ),
            ],
        });
    };

    const calculateNetWeight = (grossWeight: number, tare: number) =>
        grossWeight - tare;

    const calculateNetWeightInTons = (netWeight: number) =>
        Number(netWeight / EditScaleTicketConstants.lbsForTon);

    const calculateTotalNetWeight = () => {
        const total = createScaleTicketDetails.scaleTicketSegments.reduce(
            (sum, current) => sum + +current.netWeight,
            0,
        );
        const fixedTotal = parseFloat(total.toFixed(3));
        setTotalNetWeight(fixedTotal);
    };

    const calculateTotalNetInTons = () => {
        const total = createScaleTicketDetails.scaleTicketSegments.reduce(
            (sum, current) => sum + +current.netWeightInTons,
            0,
        );
        const fixedTotal = parseFloat(total.toFixed(3));
        setTotalNetWeightInTons(fixedTotal);
    };

    useEffect(() => {
        if (addRowButtonClicked && !validationInProgress) {
            addScaleTicket();
        }
    }, [addRowButtonClicked, validationInProgress]);

    useEffect(() => {
        calculateTotalNetWeight();
        calculateTotalNetInTons();
    }, [createScaleTicketDetails.scaleTicketSegments]);

    const advanceScaleTicketDetailsHandler = (
        fieldName: IFilterFieldBasicDetails,
        fieldValue: string | number | Date | null,
        index: number,
        type?: string,
    ) => {
        if (fieldValue === scaleTicketLabelConstants.createTruckingCompany) {
            truckingCompanyModal.open();
            setTruckingCompanyIndex(index);
        } else {
            const isValid = validateAccordingType(type, fieldValue);

            if (isValid) {
                const updatedSegments = [
                    ...createScaleTicketDetails.scaleTicketSegments,
                ];
                const segment = updatedSegments[index];
                const updatedSegment = {
                    ...segment,
                    [fieldName]: fieldValue,
                };

                if (
                    fieldName ===
                        EditScaleTicketConstants.apiFields.grossWeight ||
                    fieldName === EditScaleTicketConstants.apiFields.tare
                ) {
                    const netWeight = calculateNetWeight(
                        +updatedSegment.grossWeight,
                        +updatedSegment.tare,
                    );
                    const netWeightInTons = calculateNetWeightInTons(netWeight);
                    updatedSegment.netWeight = netWeight;
                    updatedSegment.netWeightInTons = netWeightInTons;
                }

                updatedSegments[index] = updatedSegment;
                scaleTicketDetailsErrorHandler(
                    'scaleTicketSegmentError',
                    fieldName,
                    index,
                );
                updateScaleTicketDetails(updatedSegments);
            }
        }
    };

    const handleCloseTruckingCompanyModal = (
        isTruckingCompanyCreate = false,
        truckingCompanyId?: number,
    ) => {
        truckingCompanyModal.close();
        if (isTruckingCompanyCreate && truckingCompanyId) {
            advanceScaleTicketDetailsHandler(
                'truckingCompanyId',
                truckingCompanyId,
                truckingCompanyIndex,
            );
            dispatch(fetchTruckingCompanies());
        }
    };

    const validateTicketNumberOnBlur = async (
        ticketNumber: string,
        index: number,
    ) => {
        setValidationInProgress(true);
        if (feedlotId) {
            const response = await isTicketNumberExists(
                feedlotId,
                ticketNumber,
            );
            const duplicateTicketNumberCount =
                createScaleTicketDetails.scaleTicketSegments.filter(
                    ticket => ticket.scaleTicketNumber === ticketNumber,
                ).length;
            const ticketNumberExist =
                (response && response.length > 0) ||
                duplicateTicketNumberCount > 1;
            const errorCopy = {
                ...createScaleTicketDetailsError,
                scaleTicketSegmentError:
                    createScaleTicketDetailsError.scaleTicketSegmentError.map(
                        (segmentError, idx) =>
                            idx === index
                                ? {
                                      ...segmentError,
                                      scaleTicketNumber: ticketNumberExist,
                                  }
                                : segmentError,
                    ),
            };
            updateScaleTicketErrors(errorCopy);
            setValidationInProgress(false);
        }
    };
    return (
        <>
            {isCA8340On_CompanyList ? (
                <TruckingCompanyModal
                    showModal={truckingCompanyModal.isOpen}
                    closeModalWithChanges={handleCloseTruckingCompanyModal}
                    editTruckingCompanyDetails={
                        TRUCKING_COMPANY_INITIAL_STATE.createTruckingCompanyInitial
                    }
                />
            ) : (
                <CreateTruckingCompanyModal
                    show={truckingCompanyModal.isOpen}
                    onHide={truckingCompanyModal.close}
                />
            )}
            <div className="col-12">
                {createScaleTicketDetails.scaleTicketSegments.map(
                    (advanceScaleTicketDetail, index) => (
                        <div className="row mt-2 mb-4" key={index}>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        name={
                                            EditScaleTicketConstants.apiFields
                                                .scaleTicketNumber
                                        }
                                        value={
                                            advanceScaleTicketDetail.scaleTicketNumber
                                        }
                                        className={`text-secondary form-item border border-${
                                            createScaleTicketDetailsError
                                                .scaleTicketSegmentError[index]
                                                .scaleTicketNumber
                                                ? 'danger'
                                                : ''
                                        } rounded`}
                                        onBlur={e => {
                                            validateTicketNumberOnBlur(
                                                e.target.value,
                                                index,
                                            );
                                        }}
                                        onChange={e => {
                                            advanceScaleTicketDetailsHandler(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                            );
                                        }}
                                        placeholder={
                                            CreateScaleTicketConstants
                                                .placeholders.scaleTicket
                                        }
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        name={
                                            EditScaleTicketConstants.apiFields
                                                .grossWeight
                                        }
                                        value={
                                            advanceScaleTicketDetail.grossWeight
                                        }
                                        className={`text-secondary border border-${
                                            createScaleTicketDetailsError
                                                .scaleTicketSegmentError[index]
                                                .grossWeight
                                                ? 'danger'
                                                : ''
                                        } rounded`}
                                        onChange={e => {
                                            advanceScaleTicketDetailsHandler(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                                'float',
                                            );
                                        }}
                                        placeholder={
                                            CreateScaleTicketConstants
                                                .placeholders.grossWeight
                                        }
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        name={
                                            EditScaleTicketConstants.apiFields
                                                .tare
                                        }
                                        value={advanceScaleTicketDetail.tare}
                                        className={`text-secondary border border-${
                                            createScaleTicketDetailsError
                                                .scaleTicketSegmentError[index]
                                                .tare
                                                ? 'danger'
                                                : ''
                                        } rounded`}
                                        onChange={e => {
                                            advanceScaleTicketDetailsHandler(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                                'float',
                                            );
                                        }}
                                        placeholder={
                                            CreateScaleTicketConstants
                                                .placeholders.tare
                                        }
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        value={advanceScaleTicketDetail.netWeight.toLocaleString()}
                                        className="text-secondary"
                                        type="text"
                                        size="sm"
                                        disabled
                                    />
                                </Form.Group>
                            </div>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        value={advanceScaleTicketDetail.netWeightInTons.toLocaleString()}
                                        className="text-secondary"
                                        type="text"
                                        size="sm"
                                        disabled
                                    />
                                </Form.Group>
                            </div>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        name={
                                            EditScaleTicketConstants.apiFields
                                                .dmPercentage
                                        }
                                        value={
                                            advanceScaleTicketDetail.dmPercentage
                                        }
                                        className={`text-secondary form-item border rounded`}
                                        onChange={e => {
                                            advanceScaleTicketDetailsHandler(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                                'percentage',
                                            );
                                        }}
                                        placeholder={
                                            CreateScaleTicketConstants
                                                .placeholders.dmPercentage
                                        }
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        name={
                                            EditScaleTicketConstants.apiFields
                                                .billOfLading
                                        }
                                        value={
                                            advanceScaleTicketDetail.billOfLading
                                        }
                                        onChange={e => {
                                            advanceScaleTicketDetailsHandler(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                            );
                                        }}
                                        placeholder={
                                            CreateScaleTicketConstants
                                                .placeholders.billOfLading
                                        }
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        name={
                                            EditScaleTicketConstants.apiFields
                                                .truckingCompanyId
                                        }
                                        value={
                                            advanceScaleTicketDetail.truckingCompanyId
                                        }
                                        className={`text-secondary border border-${
                                            createScaleTicketDetailsError
                                                .scaleTicketSegmentError[index]
                                                .truckingCompanyId
                                                ? 'danger'
                                                : ''
                                        } rounded`}
                                        onChange={e => {
                                            advanceScaleTicketDetailsHandler(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                            );
                                        }}
                                        as="select"
                                        size="sm"
                                    >
                                        {truckingCompanies?.map(
                                            (
                                                truckingCompany,
                                                index: number,
                                            ) => (
                                                <option
                                                    key={index}
                                                    value={truckingCompany.id}
                                                >
                                                    {truckingCompany.name}
                                                </option>
                                            ),
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col col-1">
                                <Form.Group>
                                    <Form.Control
                                        name={
                                            EditScaleTicketConstants.apiFields
                                                .driverName
                                        }
                                        value={
                                            advanceScaleTicketDetail.driverName
                                        }
                                        className="text-secondary"
                                        onChange={e => {
                                            advanceScaleTicketDetailsHandler(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                            );
                                        }}
                                        placeholder={
                                            CreateScaleTicketConstants
                                                .placeholders.driverName
                                        }
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </div>
                            {isCA9800On_ContractsMVP && (
                                <div className="col col-1">
                                    <Form.Group>
                                        <Form.Control
                                            name={
                                                EditScaleTicketConstants
                                                    .apiFields.freightRate
                                            }
                                            value={
                                                advanceScaleTicketDetail.freightRate
                                            }
                                            className={`text-secondary border border-${
                                                createScaleTicketDetailsError
                                                    .scaleTicketSegmentError[
                                                    index
                                                ].freightRate
                                                    ? 'danger'
                                                    : ''
                                            } rounded`}
                                            onChange={e => {
                                                advanceScaleTicketDetailsHandler(
                                                    e.target.name,
                                                    e.target.value,
                                                    index,
                                                );
                                            }}
                                            placeholder={
                                                CreateScaleTicketConstants
                                                    .placeholders.freightRate
                                            }
                                            type="text"
                                            size="sm"
                                        />
                                    </Form.Group>
                                </div>
                            )}
                            <div className="col-1 custom-width">
                                <Form.Group>
                                    <Form.Control
                                        name={
                                            EditScaleTicketConstants.apiFields
                                                .notes
                                        }
                                        value={advanceScaleTicketDetail.notes}
                                        className="text-secondary"
                                        onChange={e => {
                                            advanceScaleTicketDetailsHandler(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                            );
                                        }}
                                        placeholder={
                                            CreateScaleTicketConstants
                                                .placeholders.comments
                                        }
                                        as="textarea"
                                        rows={1}
                                    />
                                </Form.Group>
                            </div>
                            {createScaleTicketDetails.scaleTicketSegments
                                .length > 1 && (
                                <div className="col-1 delete-button">
                                    <DeleteIcon
                                        className="cursor-pointer delete-icon"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => removeScaleTicket(index)}
                                    />
                                </div>
                            )}
                        </div>
                    ),
                )}
            </div>

            <div className="d-flex p-3 justify-content-end col-12">
                <AddRowButton
                    onClick={() => {
                        setAddRowButtonClicked(true);
                        addScaleTicket();
                    }}
                />
            </div>
            <hr />
            <div className="scale-ticket-total-section my-4">
                <div className="col-3 mx-2 my-4">
                    {EditScaleTicketConstants.labels.netTotal}
                    <span className="mx-4">{totalNetWeight}</span>
                </div>
                <div className="col-3 mx-2 my-4">
                    {EditScaleTicketConstants.labels.netTonsTotal}
                    <span className="mx-4">{totalNetWeightInTons}</span>
                </div>
            </div>
        </>
    );
};

export default CreateScaleTicketAdvanceDetails;
